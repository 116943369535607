export const LOGIN_BY_MAIL = 'Login by mail';
export const COMPANY = 'Company';
export const ORGANIZATION_NAME = 'Organization name';
export const PASSWORD = 'Password';
export const REMEMBER_ME = 'Remember me';
export const FORGOT_PASSWORD = 'Forgot password';
export const SIGN_IN = 'Sign in';
export const NO_ACCOUNT = 'No account';
export const SIGN_UP = 'Sign up';
export const I_AGREE_TO_THE_TERMS_OF_USE = 'I agree to the Terms of Use';
export const THE_CONNECTION_WAS_SUCCESSFUL = 'The connection was successful';
export const THANK_YOU = 'thank you';
export const SERVER_ERROR = 'Server Error';
export const MISSING_DETAILS = 'Missing details';
export const PLEASE_SCAN_THE_BARCODE = 'Please scan the barcode';
export const NO_MATCH_MESSAGE = 'You are not allowed to connect with this number, do you want to connect with another number ? (Clicking Cancel will take you out of the site)';
export const CANCEL = 'Cancel';
export const OK = 'OK';
export const YOU_ARE_LOGGED_IN_WITH_THIS_PHONE = 'You are logged in with this phone';
export const TO_PROCEED_PLEASE_READ_AND_APPROVE_OUR_TERMS_OF_SERVICE = 'To proceed, please read and approve our terms of service';
export const YOU_CAN_RECEIVE_AND_SEND = 'Now you can send and receive Whatsapp messages using your mobile';
export const MOBILE2CRMCOM = 'https://mobile2crm.com';
export const STATUS = 'Status';
export const LAST_MESSAGE_TIME = 'Last message time';
export const CONNECTION_OR_DISCONNECTION_TIME = 'Connection / Disconnection time';
export const RELOAD = 'Reload';
export const DISCONNECTION = 'Disconnection';
export const PHONE_NUMBER = 'Phone Number';
export const ACTIVE = 'Active';
export const USER_NAME = 'User Name';
export const SPIKKO_SESSIONS = 'Mobile2CRM Sessions';
export const YES = 'Yes';
export const NO = 'No';
export const NO_DATA_TO_DISPLAY = 'No data to display';
export const LOGOUT = 'Logout';
export const lOGOUT_SESSION = 'Logout session';
export const ARE_YOU_SURE = 'are you sure?';
export const SET_AS_DISCONNECTED = 'Set as disconnected';
export const MANAGED_SESSIONS_FOR = 'Managed Sessions for';
export const SPIKKO_SESSIONS_FOR = 'Mobile2CRM Sessions for';
export const FAILED_TO_RESTART = 'Failed to restart';
export const STATUS_CHANGED = 'Status changed';
export const CREATE_KEEP_ALIVE = 'Create Keep alive';
export const EDIT_KEEP_ALIVE = 'Edit Keep alive';
export const STOP_KEEP_ALIVE = 'Stop Keep alive';
export const KEEP_ALIVE_FORM = 'Keep alive form';
export const SEND_MESSAGE_FORM = 'Send message form';
export const START_AT = 'Start at';
export const MESSAGE_CONTENT = 'Message content';
export const SAVE = 'Save';
export const REPEAT = 'Repeat';
export const KEEP_ALIVE_CREATED_MSG = 'The Keep alive created successfully';
export const KEEP_ALIVE_UPDATED_MSG = 'The Keep alive updated successfully';
export const KEEP_ALIVE_STOPPED_MSG = 'The Keep alive stopped successfully';
export const UPDATE_SUCCESSFUL = 'Update Successful';
export const RELOADED_SUCCESSFULLY = 'Reloaded Successfully';
export const FAILED_TO_RELOAD = 'Failed to reload';
export const NOT_AVAILABLE = 'Not available';
export const THE_PHONE_NUMBER_IS_INVALID = 'The phone number is invalid';
export const SEND_MESSAGE = 'Send Message';
export const SEND = 'Send';
export const MESSAGE_SENDING_FORM = 'Message sending form';
export const CHOOSE_A_TIME = 'Choose a time';
export const FROM_NOW_AND_ON = 'From now and on';
export const HOURS = 'Hours';
export const FROM_NOW_AND_ON_TEXT = 'You have chosen to save the messages from now on';
export const SAVE_RECENT_MESSAGES_TEXT = 'You have chosen to save the messages from the last';
export const SUBMIT = 'submit';
export const THE_APPLICATION_WAS_SUCCESSFULLY_RECEIVED = 'The application was successfully received';
export const LAST_MESSAGES_HEADER = "You can save this phone's past messages";
export const SELECT_LAST_MESSAGES_HEADER = "how far back would you like to save";
export const RETRIEVING_MESSAGES_FROM_LAST = 'Retrieving messages from last';
export const MESSAGE_SAVING_COMPLETED_SUCCESSFULLY = 'Message saving completed successfully';
export const CHOOSE_SESSIONS = 'Choose sessions';
export const KEEP_ALIVE_IT_WILL_KEEPS_TRACK_OF = 'Keep alive it will keeps track of';
export const SESSIONS = 'sessions';
export const SELECT_ALL = 'Select all';
export const KEEP_ALIVE = 'Keep alive';
export const RECONNECTING = 'Reconnecting';
export const SAVE_THE_MESSAGES_OF_THE_LAST_X_HOURS = 'Save the messages of the last';
export const KEEP_ALIVE_NAME = 'Keep alive name';
export const SELECT_ORGANIZATION = 'Select organization';
export const NO_ACTIVE_SESSION_MSG = 'There are no active sessions for this organization';
export const ORGANIZATION_ID = 'organization id';
export const YOU_MUST_TO_SELECT_ORGANIZATION = 'You must to select organization';