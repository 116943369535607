import React, { useState, useEffect } from 'react';
import SessionListCheckbox from './sessionListCheckbox';
import './keepAliveModalSessionList.css';
import * as STRINGS from '../../strings/english';


export default function KeepAliveModalSessionList(props) {

    const [checked, setChecked] = useState(false);
    const [allChecked, setAllChecked] = useState(false);

    useEffect(() => {
        const activeSessionsArr = props.sessions.filter(
            item => item.active
        );
        if (props.selectedSessions.length === activeSessionsArr.length) {
            setChecked(true);
            setAllChecked(true);
        } else {
            setChecked(false);
            setAllChecked(false);
        }
    }, [
        props.selectedSessions,
        props.sessions,
        props.sessions.length
    ])

    const onChangeHandle = (e) => {
        if (e.target.checked) {
            props.setSelectedSessions(
                props.sessions.filter(
                    item => item.active
                )
            );
        } else {
            props.setSelectedSessions([]);
        }
    }

    const returnDropdownItems = () => {
        return props.sessions.map((s, i) => {
            if (s.active) {
                return (
                    <div
                        key={`dropdown-session-item-${i}`}
                    >
                        <li
                            className="dropdown-item"
                        >
                            <SessionListCheckbox
                                setSelectedSessions={props.setSelectedSessions}
                                selectedSessions={props.selectedSessions}
                                session={s}
                                index={i}
                                userSchedule={props.userSchedule}
                                allChecked={allChecked}
                            />
                        </li>
                        {i !== props.sessions.length - 1 &&
                            <div
                                className='keep-alive-dropdown-menu-divider'
                            />}
                    </div>
                );
            } else return null;
        })
    }

    return (
        <div className={
            `btn-group ${props.isWAApiUser ? 'keep-alive-dropdown-main' : ''}`
        }>
            <button
                className="btn btn-secondary dropdown-toggle keep-alive-dropdown"
                type="button"
                id="choose-sessions-btn"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
            >
                {`${STRINGS.CHOOSE_SESSIONS}...`}
            </button>
            <ul
                className="dropdown-menu dropdown-menu-lg-end keep-alive-dropdown-menu"
                aria-labelledby="choose-sessions-btn"
            >
                <div className="form-check keep-alive-dropdown-check-all">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id={'checkbox-select-all'}
                        checked={checked}
                        onChange={e => {
                            setChecked(e.target.checked);
                            onChangeHandle(e);
                        }}
                    />
                    <label className="form-check-label" htmlFor={'checkbox-select-all'}>
                        {STRINGS.SELECT_ALL}
                    </label>
                </div>
                <li><hr className="dropdown-divider" /></li>
                {returnDropdownItems()}
            </ul>
        </div>
    )
}
