import React, { useState, useEffect } from 'react';
import * as STRINGS from '../../strings/english';
import './sessionListCheckbox.css';

export default function SessionListCheckbox(props) {

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (props.allChecked) {
            setChecked(true);
        } else setChecked(false);
    }, [props.allChecked])

    useEffect(() => {
        let session;
        if (props.userSchedule.Clients) {
            session = props.userSchedule.Clients.find(
                item => item.phone_number === props.session.phone_number
                    && new Date(item.createdAt).getTime() === new Date(props.session.createdAt).getTime()
            );
        }
        if (session) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [
        props.userSchedule.Clients,
        props.session.phone_number,
        props.session.createdAt,
    ]);

    return (
        <div className="form-check session-list-main">
            <input
                className="form-check-input session-list-input"
                type="checkbox"
                id={`flexCheckChecked-${props.index}`}
                checked={checked}
                onChange={e => {
                    setChecked(e.target.checked);
                    if (e.target.checked) {
                        props.setSelectedSessions(
                            oldArray => [...oldArray, props.session]
                        );
                    } else {
                        props.setSelectedSessions(
                            props.selectedSessions.filter(
                                item => new Date(item.createdAt).getTime() !==
                                    new Date(props.session.createdAt).getTime()
                                // && item.phone_number !== props.session.phone_number
                            )
                        );
                    }
                }}
            />
            <label className="form-check-label" htmlFor={`flexCheckChecked-${props.index}`}>
                <div className='session-list-user-details'>
                    <span className='session-list-user-details-header'>
                        {`${STRINGS.USER_NAME}: `}
                    </span>
                    {props.session.user_name}
                </div>
                <div className='session-list-user-details'>
                    <span className='session-list-user-details-header'>
                        {`${STRINGS.PHONE_NUMBER}: `}
                    </span>
                    {props.session.phone_number}
                </div>
            </label>
        </div>
    )
}
