import React, { useState } from 'react';
import './reloadBtn.css';
import { Button } from 'reactstrap';
import * as STRINGS from '../../strings/english';
import { reloadSession } from '../../service/requests';

function ReloadBtn(props) {

    const [reloadIsLoader, setReloadIsLoader] = useState(false);

    const adminScreenReloadOnClick = () => {
        if (window.confirm(`${STRINGS.ARE_YOU_SURE} ?`)) {
            const session = props.elm;
            setReloadIsLoader(true);
            reloadSession(session).then(res => {
                if (res && res.data) {
                    setReloadIsLoader(false);
                    if (res.data.operationFailed) {
                        alert(res.data.operationFailedMsg)
                    } else {
                        props.updateRow(res);
                        alert(`${STRINGS.RELOADED_SUCCESSFULLY}.`)
                    }
                }
            }).catch(err => {
                setReloadIsLoader(false);
                console.log(err);
            });
        }
    }

    const spikkoSessionsReloadOnClick = () => {
        if (window.confirm(`${STRINGS.ARE_YOU_SURE} ?`)) {
            const session = props.elm;
            setReloadIsLoader(true);
            let body = {
                reloadFromSpikkoSessions: true,
                phone_number: session.PhoneNumber,
                id: session.SessionId,
                spikko_id: session.InternalSessionId,
                active: session.ClientStatus === 1 ? true : false,
            }
            reloadSession(body).then(res => {
                if (res && res.data) {
                    setReloadIsLoader(false);
                    if (res.data.operationFailed) {
                        alert(res.data.operationFailedMsg)
                    } else {
                        props.updateRow(res);
                        alert(`${STRINGS.RELOADED_SUCCESSFULLY}.`)
                    }
                    props.stableGetSessions();
                    // alert(`${res.data[0].active ?
                    //     STRINGS.RELOADED_SUCCESSFULLY :
                    //     STRINGS.FAILED_TO_RELOAD}.`);
                }
            }).catch(err => {
                setReloadIsLoader(false);
                console.log(err);
            });
        }
    }

    return (
        <Button
            onClick={() => {
                if (props.type === 'spikkoSessions') {
                    spikkoSessionsReloadOnClick();
                } else adminScreenReloadOnClick();
            }}
            id='admin-screen-reload-btn'
        >
            {STRINGS.RELOAD}
            <img
                alt='reload-icon'
                src='assets/refresh-icon.svg'
                className={`admin-screen-reload-icon${reloadIsLoader ?
                    ' rotate linear infinite' : ''}`}
            />
        </Button>
    )
}

export default ReloadBtn