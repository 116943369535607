import React, { useState, useEffect } from 'react';
import {
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner
} from 'reactstrap';
import './oldMessagesDropdown.css';
import * as STRINGS from '../../strings/english';
import { newSessionFindLastMessages } from '../../service/requests';
import { convertHoursToMs } from '../../service/tools';
import { initialLastMsgStatus } from './service';


const hours = parseInt(process.env.REACT_APP_FIXED_TIME_TO_PULL_MESSAGES);

function OldMessagesDropDown(props) {

    const [toggle, setToggle] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [historySelectedText, setHistorySelectedText] = useState(`${STRINGS.CHOOSE_A_TIME}`);
    const [historySelected, setHistorySelected] = useState(0);
    const [saveLastMsgStatus, setSaveLastMsgStatus] = useState(initialLastMsgStatus);
    const [historyHoursOption, setHistoryHoursOption] = useState([]);

    useEffect(() => {
        if (!props.newSessionCatchup || props.newSessionCatchup.length === 0) {
            setHistorySelected(hours);
            setHistorySelectedText(convertHoursToStrOfDaysAndHours(hours));
        } else if (props.newSessionCatchup.length === 1) {
            setHistorySelected(props.newSessionCatchup[0]);
            setHistorySelectedText(convertHoursToStrOfDaysAndHours(props.newSessionCatchup[0]));
        } else if (props.newSessionCatchup.length > 1) {
            setShowDropdown(true);
            setHistoryHoursOption(props.newSessionCatchup.filter(n => n > 0));
        }
    }, [])

    const submitOnClick = () => {
        if (historySelected === 0) {
            alert(`${STRINGS.THE_APPLICATION_WAS_SUCCESSFULLY_RECEIVED}.`);
            localStorage.clear();
            props.history.push('/login');
        } else {
            setSaveLastMsgStatus({ ...saveLastMsgStatus, sent: true });
            let date = new Date();
            let lateDate = new Date(date.getTime() - convertHoursToMs(historySelected));
            newSessionFindLastMessages(
                {
                    clientPhoneNum: localStorage.getItem('phone_number'),
                    time: lateDate.getTime(),
                    hoursBack: historySelected,
                }
            ).then(res => {
                if (res && res.data) {
                    alert(STRINGS.MESSAGE_SAVING_COMPLETED_SUCCESSFULLY);
                    localStorage.clear();
                    props.history.push('/login');
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    const convertHoursToStrOfDaysAndHours = (h) => {
        let str = '';
        let days = Math.floor(h / 24);
        let remainder = h % 24;
        let hours = Math.floor(remainder);
        let hourStr = hours === 1 ? 'hour' : 'hours'
        let dayStr = days === 1 ? 'day' : 'days';
        if (h < 24) {
            str = `${h} ${hourStr}`;
        } else {
            str = `${days} ${dayStr} ${hours > 0 ? `and ${hours} ${hourStr}` : ''}`
        }
        return str;
    }

    return (
        <div className='old-msg-dd-main'>
            <div className='old-msg-dd-explanation'>
                {`${STRINGS.LAST_MESSAGES_HEADER}${historyHoursOption.length > 0 ?
                    ` , ${STRINGS.SELECT_LAST_MESSAGES_HEADER} ?` : ''}`}
            </div>
            {showDropdown ?
                <Dropdown
                    toggle={() => setToggle(!toggle)}
                    isOpen={toggle}
                    className='old-msg-dd-dropdown'
                >
                    <DropdownToggle
                        caret
                        className='old-msg-dd-dropdown-toggle'
                        disabled={saveLastMsgStatus.sent}
                    >
                        {historySelectedText}
                    </DropdownToggle>
                    <DropdownMenu
                    >
                        <DropdownItem
                            onClick={(e) => {
                                setHistorySelectedText(e.target.innerText);
                                setHistorySelected(0);
                            }}
                        >
                            {STRINGS.FROM_NOW_AND_ON}
                        </DropdownItem>
                        <DropdownItem divider />
                        {historyHoursOption.map((elm, i) => {
                            return (
                                <React.Fragment key={`dropdown_item_${i}`}>
                                    <DropdownItem
                                        onClick={(e) => {
                                            setHistorySelectedText(e.target.innerText);
                                            setHistorySelected(elm);
                                        }}
                                    >
                                        {convertHoursToStrOfDaysAndHours(elm)}
                                    </DropdownItem>
                                    {i !== historyHoursOption.length - 1
                                        && <DropdownItem divider />}
                                </React.Fragment>
                            )
                        })}
                    </DropdownMenu>
                </Dropdown> :
                <div className='old-msg-dd-explanation'>
                    {`of last ${historySelectedText}`}
                </div>}
            {historySelectedText !== STRINGS.CHOOSE_A_TIME &&
                <div className='old-msg-dd-choose-time-text'>
                    {showDropdown &&
                        <div>
                            {historySelected > 0 ?
                                `${STRINGS.SAVE_RECENT_MESSAGES_TEXT} ${historySelectedText}.`
                                :
                                `${STRINGS.FROM_NOW_AND_ON_TEXT}.`}
                        </div>}
                    <Button
                        className='old-msg-dd-choose-time-submit-btn'
                        type='button'
                        onClick={submitOnClick}
                        disabled={saveLastMsgStatus.sent}
                    >
                        {STRINGS.SUBMIT}
                    </Button>
                    {saveLastMsgStatus.sent &&
                        <div className='old-msg-dd-retrieving-msg'>
                            <div className='old-msg-dd-retrieving-msg-text'>
                                {`${STRINGS.RETRIEVING_MESSAGES_FROM_LAST} ${historySelected} ${STRINGS.HOURS.toLowerCase()}...`}
                            </div>
                            <div className='old-msg-dd-retrieving-msg-spinner'>
                                <Spinner color="#075e54" />
                            </div>
                        </div>}
                </div>}
        </div>
    )
}

export default OldMessagesDropDown;