import React, { useState, useEffect, createRef } from 'react';
import './index.css';
import { Redirect } from 'react-router-dom';
import {
    Button,
    Table,
    Modal,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import * as STRINGS from '../../strings/english';
import {
    disconnectSession,
    getSessions,
    deleteSession,
    updateSession,
    getUserSchedule,
} from '../../service/requests';
import { formatTime, formatDate, returnNumArr } from '../../service/tools';
import { SessionsModalContent } from './sessionsModalContent';
import { ENUM } from '../../enum';
import { KeepAliveFormModal } from './keepAliveFormModal';
import { SendMsgFormModal } from './sendMsgModal';
import ReloadBtn from './reloadBtn';


function AdminScreen(props) {

    const searchParams = new URLSearchParams(props.location.search);
    const frameDataStr = searchParams.get('data');
    let frameDataObj = {};
    if (frameDataStr) {
        frameDataObj = JSON.parse(frameDataStr);
    }

    let initialLoginData;
    if (Object.keys(frameDataObj).length > 0) {
        initialLoginData = {
            userId: frameDataObj.userId,
            userType: frameDataObj.userType,
            allowedUsersNumbers: frameDataObj.allowedUsersNumbers,
            allowedNumbersSessions: frameDataObj.allowedNumbersSessions,
        }
    }
    if (props.location && props.location.state) {
        initialLoginData = {
            userId: props.location.state.userId || 0,
            userType: props.location.state.userType || '',
            allowedUsersNumbers: props.location.state.allowedUsersNumbers || [],
            allowedNumbersSessions: props.location.state.allowedNumbersSessions || {},
        }
    }

    const [sessions, setSessions] = useState([]);
    const [sessionsForIndicator, setSessionsForIndicator] = useState([]);
    const [openSessionsModal, setOpenSessionsModal] = useState(false);
    const [openKeepAliveModal, setOpenKeepAliveModal] = useState(false);
    const [openSendMsgModal, setOpenSendMsgModal] = useState(false);
    const [userSchedule, setUserSchedule] = useState([]);
    const [hasSchedule, sethasSchedule] = useState(false);
    const [selectSessionToSendMsg, setSelectSessionToSendMsg] = useState({});
    const [createAnotherSchedule, setCreateAnotherSchedule] = useState(false);
    const [kaIndex, setKaIndex] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [organizationsId, setOrganizationsId] = useState([]);
    const [elRefs, setElRefs] = useState([]);
    const [loginData, setLoginData] = useState(initialLoginData || {});

    const arrLength = sessions.length;
    const checkSessionsStatusTime = 60000;

    const msgEvent = (event) => {
        if (event.origin === process.env.REACT_APP_SPIKKO_WA_LOGIN) {
            setLoginData(event.data);
            localStorage.setItem('email', event.data.username);
        } else {
            return;
        }
    }

    useEffect(() => {
        window.addEventListener('message', msgEvent);
        return () => {
            window.removeEventListener('message', msgEvent);
        }
    }, [])

    useEffect(() => {
        // add or remove refs
        setElRefs((elRefs) =>
            Array(arrLength)
                .fill()
                .map((_, i) => elRefs[i] || createRef()),
        );
    }, [arrLength]);

    useEffect(() => {
        const interval = setInterval(() => {
            getSessions(
                loginData.userType,
                returnNumArr(loginData.allowedUsersNumbers),
            ).then(res => {
                if (res && res.data) {
                    checkSessionsStatus(res.data);
                }
            })
        }, checkSessionsStatusTime);

        return () => {
            clearInterval(interval);
        };
    });

    const checkSessionsStatus = (newSessions) => {
        sessions.forEach((oldSession, index) => {
            newSessions.forEach((newSession) => {
                if (oldSession.id === newSession.id) {
                    if (oldSession.active !== newSession.active) {
                        let newSessionsForIndicator = [...sessionsForIndicator];
                        newSessionsForIndicator[index].statusChanged = true;
                        setSessionsForIndicator(newSessionsForIndicator);

                        let tempSessions = [...sessions];
                        tempSessions[index].active = newSession.active;
                        setSessions(tempSessions);
                        elRefs[index].current.className = 'admin-screen-status-changed';
                    }
                    else if (newSession.restarted_successfully === false) {
                        let newSessionsForIndicator = [...sessionsForIndicator];
                        newSessionsForIndicator[index].noRestart = true;
                        setSessionsForIndicator(newSessionsForIndicator);
                        elRefs[index].current.className = 'admin-screen-no-restart';
                    }
                    else if (newSession.state === ENUM.TIMEOUT) {
                        let newSessionsForIndicator = [...sessionsForIndicator];
                        newSessionsForIndicator[index].noAvailable = true;
                        setSessionsForIndicator(newSessionsForIndicator);
                        elRefs[index].current.className = 'admin-screen-no-available';
                    }
                }
            });
        });
    }

    useEffect(() => {
        if (loginData.allowedUsersNumbers ||
            loginData.userType === ENUM.WA_API_TYPE) {
            getUserSchedule(
                loginData.userId,
                loginData.userType === ENUM.WA_API_TYPE
            ).then(res => {
                if (res.data) {
                    if (res.data.length > 0) {
                        setUserSchedule(res.data);
                        sethasSchedule(true);
                    }
                }
            });
            getSessions(
                loginData.userType,
                returnNumArr(loginData.allowedUsersNumbers),
            ).then(res => {
                if (res && res.data) {
                    setSessions(res.data);
                    setSessionsForIndicator(res.data);
                    let allOrganizationsId = res.data.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.organization_id === value.organization_id
                        ))
                    );
                    setOrganizationsId(allOrganizationsId);
                }
            })
        }
    }, [loginData]);

    const disconnectedOnClick = (session) => {
        if (window.confirm(`${STRINGS.ARE_YOU_SURE} ?`)) {
            disconnectSession(session).then(res => {
                if (res && res.data) {
                    let newSessions = [...sessions];
                    let foundIndex = newSessions.findIndex(x => x.id === session.id);
                    if (res.data.operationFailed) {
                        alert(res.data.operationFailedMsg);
                        newSessions[foundIndex].shouldBeDeleted = true;
                        setSessions(newSessions);
                    }
                    else {
                        updateRow(res);
                        turnOffNotificationUi(foundIndex);
                    }
                }
            });
        }
    }

    const updateRow = async (response) => {
        let newRow = response.data[0];
        let newSessions = [...sessions];
        let foundIndex = newSessions.findIndex(x => x.id === newRow.id);
        newSessions[foundIndex] = newRow;
        setSessions(newSessions);
    }

    const turnOffNotificationUi = (index) => {
        let newSessionsForIndicator = [...sessionsForIndicator];
        if (newSessionsForIndicator[index].noRestart ||
            newSessionsForIndicator[index].noAvailable ||
            newSessionsForIndicator[index].statusChanged) {
            delete newSessionsForIndicator[index].statusChanged;
            delete newSessionsForIndicator[index].noRestart;
            delete newSessionsForIndicator[index].noAvailable;
            setSessionsForIndicator(newSessionsForIndicator);
            elRefs[index].current.className = '';
        }
    }

    const sessionsModalContent = () => {
        setOpenSessionsModal(!openSessionsModal);
    }

    const keepAliveFormModalToggle = () => {
        setOpenKeepAliveModal(!openKeepAliveModal);
    }

    const sendMsgFormModalToggle = () => {
        setOpenSendMsgModal(!openSendMsgModal);
    }

    const logout = () => {
        localStorage.clear();
        props.history.push('/login');
    }

    const deleteOnClick = (session) => {
        deleteSession(session).then(res => {
            if (res && res.data) {
                let newSessions = [...sessions];
                newSessions.splice(newSessions.findIndex(e => e.id === session.id), 1);
                setSessions(newSessions);
            }
        })
    }

    const closeNotification = async (index) => {
        let newSessionsForIndicator = [...sessionsForIndicator];
        if (newSessionsForIndicator[index].noRestart) {
            const updateRowData = {
                updatedRow: {
                    id: sessions[index].id,
                },
                updateObj: {
                    restarted_successfully: true,
                }
            };
            const data = await updateSession(updateRowData);
            await updateRow(data);
        }
        delete newSessionsForIndicator[index].statusChanged;
        delete newSessionsForIndicator[index].noRestart;
        delete newSessionsForIndicator[index].noAvailable;
        setSessionsForIndicator(newSessionsForIndicator);
        elRefs[index].current.className = '';
    }

    const createDropdownItemText = (us, index) => {
        if (loginData.userType === ENUM.WA_API_TYPE) {
            if (us.keep_alive_name) {
                return (
                    <React.Fragment>
                        <span style={{ fontWeight: 'bold' }}>
                            {us.user_name || us.organization_id}
                        </span>
                        <span>{` - ${us.keep_alive_name}`}</span>
                    </React.Fragment>
                );
            } else return (
                <React.Fragment>
                    <span style={{ fontWeight: 'bold' }}>
                        {us.user_name || us.organization_id}
                    </span>
                    <span>{` - ${STRINGS.KEEP_ALIVE} #${index + 1}`}</span>
                </React.Fragment>
            );
        } else {
            if (us.keep_alive_name) {
                return us.keep_alive_name;
            } else return `${STRINGS.KEEP_ALIVE} #${index + 1}`;
        }
    }

    const toggle = () => setDropdownOpen(prevState => !prevState);

    if (loginData &&
        (loginData.userType === ENUM.WA_API_TYPE ||
            loginData.userType === ENUM.AGENT_TYPE)) {
        return (
            <div>
                <div className='admin-screen-btn-div'>
                    <p className='admin-screen-btn-user-name-header'>
                        {`${STRINGS.MANAGED_SESSIONS_FOR} `}
                        <span className='admin-screen-btn-user-name'>
                            {localStorage.getItem('email')}
                        </span>
                    </p>
                    <div className='admin-screen-buttons-group'>
                        {(loginData.userType !== ENUM.WA_API_TYPE ||
                            (loginData.userType === ENUM.WA_API_TYPE &&
                                organizationsId.length > 0)) &&
                            <Button
                                onClick={() => {
                                    setOpenKeepAliveModal(!openKeepAliveModal);
                                    setCreateAnotherSchedule(true);
                                }}
                                className='admin-screen-spikko-keep-alive-ml-auto'
                                id='admin-screen-spikko-keep-alive-btn-create-in-addition'
                            >
                                {STRINGS.CREATE_KEEP_ALIVE}
                            </Button>}
                        {userSchedule.length > 0 &&
                            <Dropdown
                                isOpen={dropdownOpen}
                                toggle={toggle}
                                direction={'down'}
                            >
                                <DropdownToggle
                                    className='admin-screen-edit-ka'
                                    caret
                                >
                                    {hasSchedule ?
                                        STRINGS.EDIT_KEEP_ALIVE :
                                        STRINGS.CREATE_KEEP_ALIVE}
                                </DropdownToggle>
                                <DropdownMenu >
                                    {userSchedule.map((us, i) => {
                                        return (
                                            <DropdownItem
                                                key={`edit-ka-dropdown-item-${i}`}
                                                onClick={() => {
                                                    setOpenKeepAliveModal(!openKeepAliveModal);
                                                    setCreateAnotherSchedule(false);
                                                    setKaIndex(i);
                                                }}
                                            >
                                                {createDropdownItemText(us, i)}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>}
                        <Button
                            onClick={() => setOpenSessionsModal(!openSessionsModal)}
                            className='admin-screen-spikko-sessions-btn'
                            id='admin-screen-spikko-sessions-btn'
                        >
                            {STRINGS.SPIKKO_SESSIONS}
                        </Button>
                        <Button
                            onClick={logout}
                            className='admin-screen-logout-btn'
                            id='admin-screen-logout-btn'
                        >
                            {STRINGS.LOGOUT}
                        </Button>
                    </div>

                </div>
                <div className='admin-screen-table-main'>
                    <Table
                        striped
                        responsive
                        className='admin-screen-table'
                    >
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{STRINGS.USER_NAME}</th>
                                <th>{STRINGS.PHONE_NUMBER}</th>
                                {loginData.userType === ENUM.WA_API_TYPE &&
                                    <th>{STRINGS.ORGANIZATION_ID}</th>}
                                <th>{STRINGS.ACTIVE}</th>
                                <th>{STRINGS.LAST_MESSAGE_TIME}</th>
                                <th>{STRINGS.CONNECTION_OR_DISCONNECTION_TIME}</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {sessions.length > 0 && sessionsForIndicator.length > 0 ?
                                sessions.map((elm, i) => {
                                    return (
                                        <tr key={`admin-screen-tr-${i}`} ref={elRefs[i]}>
                                            <th scope="row">{i + 1}</th>
                                            <td>{elm.user_name}</td>
                                            <td>{elm.phone_number}</td>
                                            {loginData.userType === ENUM.WA_API_TYPE &&
                                                <td>{elm.organization_id}</td>}
                                            <td>{elm.active !== null && elm.active.toString()}</td>
                                            <td>
                                                {`${formatDate(elm.last_message_time)} ${formatTime(elm.last_message_time, true)}`}
                                            </td>
                                            <td>
                                                {`${formatDate(elm.change_active_time)} ${formatTime(elm.change_active_time, true)}`}
                                            </td>
                                            <td>{elm.active &&
                                                <Button
                                                    id='admin-screen-send-msg-btn'
                                                    onClick={() => {
                                                        setSelectSessionToSendMsg(elm);
                                                        setOpenSendMsgModal(!openSendMsgModal);
                                                    }}
                                                >
                                                    {STRINGS.SEND_MESSAGE}
                                                    <img
                                                        alt='send-icon'
                                                        src='assets/whatsapp-send-icon.svg'
                                                        className='admin-screen-send-icon'
                                                    />
                                                </Button>}
                                            </td>
                                            <td>
                                                <h4 className='admin-screen-notification-reason'>
                                                    {sessionsForIndicator[i].statusChanged ? STRINGS.STATUS_CHANGED :
                                                        sessionsForIndicator[i].noRestart ? STRINGS.FAILED_TO_RESTART :
                                                            sessionsForIndicator[i].noAvailable ? STRINGS.NOT_AVAILABLE : ''}
                                                </h4>
                                            </td>
                                            <td>
                                                <ReloadBtn
                                                    elm={elm}
                                                    updateRow={updateRow}
                                                />
                                            </td>
                                            <td align='center'>
                                                {!elm.active || elm.shouldBeDeleted ?
                                                    <Button
                                                        close
                                                        size='lg'
                                                        className='admin-screen-delete-btn'
                                                        onClick={() => deleteOnClick(elm)}
                                                        block={true}
                                                    /> :
                                                    <Button
                                                        onClick={() => disconnectedOnClick(elm)}
                                                        id='admin-screen-disconnected-btn'
                                                    >
                                                        {STRINGS.lOGOUT_SESSION}
                                                        <img
                                                            alt='logout-icon'
                                                            src='assets/logout-icon.png'
                                                            className='admin-screen-logout-icon'
                                                        />
                                                    </Button>}
                                            </td>
                                            <td>
                                                {(sessionsForIndicator[i].statusChanged ||
                                                    sessionsForIndicator[i].noRestart ||
                                                    sessionsForIndicator[i].noAvailable) ?
                                                    <div className='admin-screen-close-notification-relative'>
                                                        <div
                                                            className={
                                                                `admin-screen-close-notification-absolute 
                                                                ${sessionsForIndicator[i].statusChanged ?
                                                                    'status-color' : sessionsForIndicator[i].noRestart ?
                                                                        'restart-color' : sessionsForIndicator[i].noAvailable ?
                                                                            'available-color' : ''}`}
                                                            onClick={() => closeNotification(i)}
                                                        >
                                                            <Button
                                                                close
                                                            />
                                                        </div>
                                                    </div> : <div></div>}
                                            </td>
                                        </tr>
                                    )
                                }) :
                                <tr>
                                    <th></th>
                                    <td>
                                        <h5 className='admin-screen-no-data-msg'>
                                            {`${STRINGS.NO_DATA_TO_DISPLAY}...`}
                                        </h5>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </div>
                <Modal
                    fullscreen="lg"
                    size={'xl'}
                    toggle={sessionsModalContent}
                    isOpen={openSessionsModal}
                >
                    <SessionsModalContent
                        loginData={loginData}
                    />
                </Modal>
                <Modal
                    fullscreen="lg"
                    size={'l'}
                    toggle={keepAliveFormModalToggle}
                    isOpen={openKeepAliveModal}
                    backdrop={'static'}
                    className='admin-screen-ka-form-modal'
                >
                    <KeepAliveFormModal
                        keepAliveFormModalToggle={keepAliveFormModalToggle}
                        userSchedule={!createAnotherSchedule ? userSchedule[kaIndex] : {}}
                        sethasSchedule={sethasSchedule}
                        setUserSchedule={setUserSchedule}
                        loginData={loginData}
                        sessions={sessions}
                        setCreateAnotherSchedule={setCreateAnotherSchedule}
                        organizationsId={organizationsId}
                        userType={loginData.userType}
                        setKaIndex={setKaIndex}
                    />
                </Modal>
                <Modal
                    fullscreen="lg"
                    size={'l'}
                    toggle={sendMsgFormModalToggle}
                    isOpen={openSendMsgModal}
                >
                    <SendMsgFormModal
                        sendMsgFormModalToggle={sendMsgFormModalToggle}
                        session={selectSessionToSendMsg}
                    />
                </Modal>
            </div >
        )
    } else {
        return (<Redirect to='/login' />)
    }

}

export default AdminScreen;