import React, { useState } from 'react';
import {
    Button,
    FormGroup,
    Input,
    Label,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
} from 'reactstrap';
import { createSchedule, stopSchedule } from '../../service/requests';
import { formatTime } from '../../service/tools';
import * as STRINGS from '../../strings/english';
import {
    formatInputValue,
    convertFormatNumToRegularNum,
    validPhoneNumber,
    checkArrayIfHasValueTrue
} from '../../service/tools';
import KeepAliveModalSessionList from './keepAliveModalSessionList';
import './keepAliveFormModal.css';
import { ENUM } from '../../enum';


export const KeepAliveFormModal = (props) => {
    const [keepAliveName, setKeepAliveName] = useState(props.userSchedule.keep_alive_name || '');
    const [phoneNum, setPhoneNum] = useState(formatInputValue(props.userSchedule.phone_number) || '');
    const [messageContent, setMessageContent] = useState(props.userSchedule.message_content || '');
    const [startAt, setStartAt] = useState(formatTime(props.userSchedule.start_at, false, false) || '');
    const [repeat, setRepeat] = useState(props.userSchedule.repeat || 0);
    const [selectedSessions, setSelectedSessions] = useState(props.userSchedule.Clients || []);
    const [organizationSessions, setOrganizationSessions] = useState(props.userSchedule.Clients || []);
    const [selectedOrganId, setSelectedOrganId] = useState(props.userSchedule.organization_id || '');
    const [selectedOrganUsername, setSelectedOrganUsername] = useState(props.userSchedule.user_name || '');
    const [showNoActiveSessionsforOrg, setShowNoActiveSessionsforOrg] = useState(false);

    const isWAApiUser = props.userType === ENUM.WA_API_TYPE;

    const saveOnClick = () => {
        const time = startAt.split(':');
        const date = new Date();
        date.setHours(time[0]);
        date.setMinutes(time[1]);
        date.setSeconds(0);
        date.setMilliseconds(0);
        const dbData = {
            token: localStorage.getItem('auth-data'),
            userName: isWAApiUser ? selectedOrganUsername : localStorage.getItem('email'),
            phoneNumber: convertFormatNumToRegularNum(phoneNum),
            messageContent: messageContent,
            startAt: date.getTime(),
            repeat: parseInt(repeat),
            active: true,
            organizationId: parseInt(selectedOrganId) || props.loginData.userId,
            scheduleId: props.userSchedule.id || -1,
            keepAliveName: keepAliveName,
            isWAApiUser: isWAApiUser,
        }
        const data = {
            dbData: dbData,
            selectedSessions: selectedSessions,
        }
        if (isWAApiUser && !selectedOrganId) {
            return alert(STRINGS.YOU_MUST_TO_SELECT_ORGANIZATION);
        }
        if (!startAt || repeat <= 0 || !phoneNum || !messageContent) {
            return alert(STRINGS.MISSING_DETAILS);
        }
        else if (!validPhoneNumber(phoneNum)) {
            return alert(STRINGS.THE_PHONE_NUMBER_IS_INVALID);
        }
        else {
            createSchedule(data).then(res => {
                if (res.data.length > 0) {
                    props.setUserSchedule(res.data);
                }
                props.sethasSchedule(true);
                props.keepAliveFormModalToggle();
                alert(res.data.length > 0
                    ? STRINGS.KEEP_ALIVE_UPDATED_MSG
                    : STRINGS.KEEP_ALIVE_CREATED_MSG);
            })
        }
    }

    const stopScheduleOnClick = () => {
        if (window.confirm(`${STRINGS.ARE_YOU_SURE} ?`)) {
            stopSchedule({
                token: localStorage.getItem('auth-data'),
                userName: isWAApiUser ? selectedOrganUsername : localStorage.getItem('email'),
                messageContent: messageContent,
                phoneNumber: convertFormatNumToRegularNum(phoneNum),
                repeat: parseInt(repeat),
                organizationId: parseInt(selectedOrganId) || props.loginData.userId,
                scheduleId: props.userSchedule.id,
                spikkoId: props.userSchedule.spikko_id,
                userScheduleClients: props.userSchedule.Clients,
                isWAApiUser: isWAApiUser,
            }).then(res => {
                if (res.data.scheduleDeletedSuccessfully) {
                    props.setKaIndex(0);
                    if (res.data.userSchedule.length === 0) {
                        props.sethasSchedule(false);
                        props.setCreateAnotherSchedule(true);
                    }
                    props.setUserSchedule(res.data.userSchedule);
                    alert(STRINGS.KEEP_ALIVE_STOPPED_MSG);
                    props.keepAliveFormModalToggle();
                }
            });
        }
    }

    const organIdSelectOnChange = (e) => {
        let strObj = e.target[e.target.selectedIndex].getAttribute('data-value');
        let dataObj = JSON.parse(strObj);
        setSelectedOrganId(dataObj.organization_id);
        setSelectedOrganUsername(dataObj.organization_username);
        let arr = props.sessions.filter(s => s.organization_id === dataObj.organization_id);
        if (!checkArrayIfHasValueTrue(arr)) {
            setSelectedSessions([]);
            setShowNoActiveSessionsforOrg(true);
        }
        setOrganizationSessions(arr);
    }

    return (
        <div>
            <ModalHeader
                toggle={props.keepAliveFormModalToggle}
            >
                {STRINGS.KEEP_ALIVE_FORM}
                {selectedSessions.length > 0 &&
                    <div className='keep-alive-modal-header'>
                        {STRINGS.KEEP_ALIVE_IT_WILL_KEEPS_TRACK_OF}
                        {` ${selectedSessions.length} `}
                        {STRINGS.SESSIONS}
                    </div>}
            </ModalHeader>
            <ModalBody>
                <div>
                    <FormGroup
                        className='keep-alive-dropdown-form-group'
                    >
                        {isWAApiUser &&
                            <Input
                                id="organization-id-select"
                                name="organization-id-select"
                                type="select"
                                onChange={organIdSelectOnChange}
                            >
                                <option hidden>
                                    {STRINGS.SELECT_ORGANIZATION}
                                </option>
                                {props.organizationsId.map((elm, i) => {
                                    return (
                                        <option
                                            key={`option-organization-id-select-${i}`}
                                            data-value={JSON.stringify(elm)}
                                        >
                                            {elm.organization_username || elm.organization_id}
                                        </option>
                                    );
                                })}
                            </Input>}
                        {(!isWAApiUser || organizationSessions.length > 0) &&
                            checkArrayIfHasValueTrue(
                                !isWAApiUser ? props.sessions : organizationSessions
                            ) &&
                            <KeepAliveModalSessionList
                                sessions={
                                    !isWAApiUser ? props.sessions : organizationSessions}
                                setSelectedSessions={setSelectedSessions}
                                selectedSessions={selectedSessions}
                                userSchedule={props.userSchedule}
                                isWAApiUser={isWAApiUser}
                            />}
                    </FormGroup>
                    {isWAApiUser && showNoActiveSessionsforOrg &&
                        !checkArrayIfHasValueTrue(organizationSessions) &&
                        <FormGroup
                            className='keep-alive-no-active-session-msg'>
                            <h5>
                                <Badge>
                                    {STRINGS.NO_ACTIVE_SESSION_MSG}
                                </Badge>
                            </h5>
                        </FormGroup>}
                    <FormGroup>
                        <Label for="keep-alive-name">
                            {STRINGS.KEEP_ALIVE_NAME}
                        </Label>
                        <Input
                            id="keep-alive-name"
                            onChange={e => setKeepAliveName(e.target.value)}
                            value={keepAliveName}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="keep-alive-phone-num-input">
                            {STRINGS.PHONE_NUMBER}
                        </Label>
                        <Input
                            id="keep-alive-phone-num-input"
                            name="keep-alive-phone-num-input"
                            type="text"
                            value={formatInputValue(phoneNum)}
                            onChange={(e) => setPhoneNum(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="keep-alive-start-at-num-input">
                            {STRINGS.START_AT}
                        </Label>
                        <Input
                            id="keep-alive-start-at-num-input"
                            name="keep-alive-start-at-num-input"
                            type="time"
                            value={startAt}
                            onChange={(e) => setStartAt(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="keep-alive-return-num-input">
                            {STRINGS.REPEAT}
                        </Label>
                        <Input
                            id="keep-alive-return-num-input"
                            name="keep-alive-return-num-input"
                            type="number"
                            value={repeat}
                            onChange={(e) => {
                                setRepeat(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="keep-alive-message-content-input">
                            {STRINGS.MESSAGE_CONTENT}
                        </Label>
                        <Input
                            id="keep-alive-message-content-input"
                            name="text"
                            type="textarea"
                            value={messageContent}
                            onChange={(e) => setMessageContent(e.target.value)}
                        />
                    </FormGroup>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={props.keepAliveFormModalToggle}
                    outline
                >
                    {STRINGS.CANCEL}
                </Button>
                {props.userSchedule.active &&
                    <Button
                        color="danger"
                        outline
                        onClick={stopScheduleOnClick}
                    >
                        {STRINGS.STOP_KEEP_ALIVE}
                    </Button>}
                <Button
                    onClick={saveOnClick}
                    id='keep-alive-save-btn'
                >
                    {STRINGS.SAVE}
                </Button>
            </ModalFooter>
        </div>
    )
}