import axios from "axios";
import { CONSTS } from "./consts";
import { ENUM } from '../enum';

export const getSessions = (userType, userNumbers) => {
    return axios.post(CONSTS.GET_SESSIONS, {
        userType,
        userNumbers,
    });
}

export const getSessionsFromSpikko = (userType, userNumbers) => {
    return axios.post(CONSTS.GET_SESSIONS_FROM_SPIKKO, {
        userType,
        userNumbers
    });
}

export const reloadSession = (body) => {
    return axios.put(CONSTS.RELOAD_SESSION_PATH, body);
}

export const disconnectSession = (body) => {
    return axios.put(CONSTS.DISSCONNECT_SESSION_PATH, body);
}

export const deleteSession = (body) => {
    return axios.delete(CONSTS.DELETE_SESSION_PATH, {
        data: body
    });
}

export const setAsDisconnected = (body) => {
    return axios.post(CONSTS.SET_AS_DISCONNECTED_PATH, body);
}

export const updateSession = (body) => {
    return axios.put(CONSTS.UPDATE_SESSION, body);
}

export const createSchedule = (body) => {
    return axios.post(CONSTS.CREATE_SCHEDULE, body);
}

export const getUserSchedule = (organizationId, isWAApiUser) => {
    return axios.get(CONSTS.GET_USER_SCHEDULE,
        {
            params: {
                userName: isWAApiUser ?
                    ENUM.WA_API_TYPE : localStorage.getItem('email'),
                organizationId: organizationId || null,
            }
        }
    );
}

export const stopSchedule = (body) => {
    return axios.put(CONSTS.STOP_SCHEDULE, body);
}

export const specificSessionSendMsg = (body) => {
    return axios.post(CONSTS.SPECIFIC_SESSION_SEND_MSG, body);
}

export const newSessionFindLastMessages = (body) => {
    return axios.post(CONSTS.NEW_SESSION_FIND_LAST_MESSAGES, body);
}